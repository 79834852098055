
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Layout from '../Layout/Layout';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Box,
  TextField,
  Autocomplete,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '../context/ThemeContext';
import { Helmet } from "react-helmet";


import NativeAd from './NativeAd';

const QuizPlayList = () => {
  const [quizzes, setQuizzes] = useState([]);
  const navigate = useNavigate();
  const [theme] = useTheme();

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchQuizzes();
  }, []);

  const fetchQuizzes = async () => {
    try {
      const response = await axios.get('/api/v1/quizzes/all');
      setQuizzes(response.data);
    } catch (error) {
      console.error('Error fetching quizzes:', error);
    }
  };

  const handleSearchSelect = (event, value) => {
    if (value) {
      navigate(`/play/${value._id}`);
    }
  };

  return (
    <Layout>



<Helmet>
        <title>Physics, Chemistry, Maths, JEE, NEET & Tech Quizzes - PDF Baba</title>
        <meta
          name="description"
          content="Practice quizzes for JEE, NEET, 12th Science (Physics, Chemistry, Maths, Biology), Maharashtra HSC, CBSE Board exams, and technology topics like Node.js, React.js, JavaScript, AI, and more."
        />
        <meta
          name="keywords"
          content="Physics quiz, Chemistry quiz, Maths MCQs, Biology quiz, JEE practice test, NEET mock test, 12th Science Board, Maharashtra HSC, CBSE exams, competitive exams, Node.js quiz, React.js test, JavaScript MCQs, AI quizzes, Python test, programming MCQs"
        />
        <meta name="author" content="PDF Baba" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://pdf-baba.com/quizplaylist" />
      </Helmet>






      <Box
        marginTop="100px"
        sx={{
          px: { xs: 2, sm: 4 },
          py: 3,
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >






        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{
            textAlign: 'center',
            mb: 3,
            fontFamily: 'Poppins, sans-serif',
            fontSize: { xs: '1.2rem', sm: '1.5rem' },
            color: theme === 'dark' ? 'white' : 'black',
          }}
        >
          Available Quizzes
        </Typography>




        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3, width: '100%' }}>
          <Autocomplete
            options={quizzes}
            getOptionLabel={(option) => option.title}
            onChange={handleSearchSelect}
            sx={{
              width: { xs: '100%', sm: '50%' },
              backgroundColor: theme === 'dark' ? '#333' : '#fff',
              borderRadius: '5px',
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Quiz"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <SearchIcon sx={{ color: theme === 'dark' ? '#bbb' : '#000' }} />
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
                sx={{
                  backgroundColor: theme === 'dark' ? '#333' : '#fff',
                  borderRadius: '5px',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { borderColor: theme === 'dark' ? '#bbb' : '#ccc' },
                    '&:hover fieldset': { borderColor: theme === 'dark' ? '#fff' : '#000' },
                  },
                }}
              />
            )}
          />
        </Box>

        <Grid container spacing={3} justifyContent="center">
          {quizzes.map((quiz) => (
            <Grid item xs={6} sm={6} md={4} key={quiz._id}>
              <Card
                sx={{
                  backgroundColor: theme === 'dark' ? '#1e1e1e' : 'white',
                  color: theme === 'dark' ? 'white' : 'black',
                  boxShadow: theme === 'dark'
                    ? '0px 4px 10px rgba(255,255,255,0.1)'
                    : '0px 4px 10px rgba(0,0,0,0.1)',
                  transition: '0.3s',
                  '&:hover': { transform: 'scale(1.02)' },
                }}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: { xs: '0.9rem', md: '1.1rem' },
                    }}
                  >
                    {quiz.title}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: '0.8rem', opacity: 0.8 }}>
                    {quiz.category}
                  </Typography>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      mt: 2,
                      backgroundColor: theme === 'dark' ? '#068fc6' : '#1976d2',
                      color: 'white',
                      '&:hover': { backgroundColor: theme === 'dark' ? '#388e3c' : '#125ea2' },
                    }}
                    onClick={() => navigate(`/play/${quiz._id}`)}
                  >
                    Start Quiz
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box>
        <NativeAd />
      </Box>
    </Layout>
  );
};

export default QuizPlayList;
